/*
  ? This is far too stupid to be worth cleaning up.
 */

import React, { useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { PageProps } from 'gatsby'
import { LayoutContext } from 'src/features/layout'
import SEO from 'src/common/seo'
import YoutubeAudioButton from 'src/features/youtube-audio-button'

import { useStaticQuery, graphql } from 'gatsby'
// import { ValenjosefQuery } from 'types/graphql-types'
import BackgroundImage from 'gatsby-background-image'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

const useValenjosefFluidData = () => {
  const { allFile } = useStaticQuery<any>(graphql`
    query Valenjosef {
      allFile(
        filter: {
          relativePath: { eq: "valenjosef/Valenjosef.png" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  return allFile.nodes[0].childImageSharp?.fluid
}

const Valentine = ({ location }: PageProps) => {
  const props = useMotionNavOffsetStyle(
    { alignment: 'center' },
    { dodge: 'none' }
  )
  const backgroundProps = useMotionNavOffsetStyle(
    { cssProp: 'margin', alignment: 'center' },
    { dodge: 'none' }
  )
  const valenjosefFluidData = useValenjosefFluidData()
  const { setLocation } = useContext(LayoutContext)

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEO title="Valentine" />
      <ValenjosefContainer {...props}>
        <WhiteBg {...backgroundProps} />
        <HeartContainer {...props}>
          <Heart />
        </HeartContainer>
        <Valenjosef Tag="div" fluid={valenjosefFluidData as any}></Valenjosef>
      </ValenjosefContainer>

      <YoutubeAudioButton videoId="k-HdGnzYdFQ" playerVars={{ autoplay: 1 }} />
    </>
  )
}

export default React.memo(Valentine)

const size = 50
const HeartContainer = styled(motion.div)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const Heart = styled.div`
  position: relative;
  background-color: red;
  opacity: 0.4;
  height: ${size}vmin;
  width: ${size}vmin;
  transform: rotate(-45deg);
  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: red;
    border-radius: 50%;
    height: ${size}vmin;
    width: ${size}vmin;
  }
  &:before {
    top: -${size / 2}vmin;
    z-index: -1;
  }
  &:after {
    top: 0;
    right: -${size / 2}vmin;
    z-index: -1;
  }
`

const ValenjosefContainer = styled(motion.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`
const WhiteBg = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
`
const Valenjosef = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: bottom;
  filter: drop-shadow(0px 0px 2px rgba(255, 0, 0, 1));
`
